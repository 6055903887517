import httpService from "../helpers/http.service";

export default {
   registerSome(data){
       const request = [];
       data.forEach(x => {
           request.push(this.register(x))
       });
       return Promise.all(request);
   },
    delete(id){
       return httpService.delete('pasos/' + id);
    },
    register(payload) {
        let url = 'pasos';
        url += payload.saved ? '/' + payload.id : '';
        delete payload.saved;
        delete payload.id;
        return httpService.post(url, payload)
    }
};
